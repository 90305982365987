import React, { useState, useEffect, useRef } from "react";
import "./ScientificCalculator.css";
// import * as math from "mathjs";
// import { evaluate } from "mathjs";
import { createSoftTag, updateSoftTag } from "../../ReportManagement/service";
// import { evaluate } from "mathjs";
import { toast } from "react-toastify";

export const ScientiFicCalculator = function ScientificCalculator(props) {
  // const [Result, setResult] = useState();
  // const [selectedActualFormula, setselectedActualFormula] = useState();
  const [matchList, setMatchList] = useState();
  const [selectedFormula, setselectedFormula] = useState();
  const [hasemptyParentheses, setHasEmptyParentheses] = useState(false);
  const [multiple_Consecutive_Operators, setmultiple_Consecutive_Operators] =
    useState(false);
  const [formula_Starts_With_Operator, setformula_Starts_With_Operator] =
    useState(false);
  const [formula_End_With_Operator, setformula_End_With_Operator] =
    useState(false);
  const [inValidCharactersRegex, setinValidCharactersRegex] = useState(false);
  const [apiFormula, setApiFormula] = useState(""); // State for API formula
  const [tagIds, setTagIds] = useState([]); // State for storing tag IDs

  const selectedTags = props.SelectedTags;

  const [names, setnames] = useState([
    "cos()+sin()+tan()",
    "sin()+cos()+tan()",
    "tan()+cos()+sin()",
    "sin()",
    "sin()+sin()",
    "sin()+sin()+sin()",
    "sin()+sin()+sin()+sin()",
    "tan()+tan()+tan()+tan()+tan()+tan()",
    "cos()+cos()+cos()+cos()+cos()+cos()",
  ]);
  const TagName = props.Tag_Name_data ? props.Tag_Name_data : "";
  const Description = props.Tag_Description_data
    ? props.Tag_Description_data
    : "";
  const DeviceID = props.Device_ID ? props.Device_ID : "";

  const Button_disable = props.button_disable;
  const selectedTag = props.SelectedTags ? props.SelectedTags : [];

  useEffect(() => {
    try {
      if (props.addtag) {
        console.log("props.tags", props.addtag);
        handleButtonClick(props.addtag);
        props.onaddTag(props.addtag);
      }
    } catch (e) {
      console.log("Error from AddTag in Formula....", e);
    }

    if (props.removetag) {
      try {
        handleRemoveTag(props.removetag);
        props.onRemoveTag(props.removetag);
      } catch (e) {
        console.log(" error tag removing time", e);
      }
    }
  }, [props.addtag, props.removetag]);

  useEffect(() => {
    try {
      if (selectedFormula) {
        // Define regex patterns once
        const regexPatterns = {
          hasEmptyParentheses: /\(\s*\)/,
          multipleConsecutiveOperators: /[-+*/]{2,}/,
          formulaStartsWithOperator: /^[+*/-]/,
          formulaEndsWithOperator: /[+*/-]$/,
          invalidCharacters: /[,?'";]/,
        };

        // Check conditions and update state in a single operation
        const results = {
          hasEmptyParentheses:
            regexPatterns.hasEmptyParentheses.test(selectedFormula),
          multipleConsecutiveOperators:
            regexPatterns.multipleConsecutiveOperators.test(selectedFormula),
          formulaStartsWithOperator:
            regexPatterns.formulaStartsWithOperator.test(selectedFormula),
          formulaEndsWithOperator:
            regexPatterns.formulaEndsWithOperator.test(selectedFormula),
          invalidCharactersRegex:
            regexPatterns.invalidCharacters.test(selectedFormula),
        };
        console.log("selectedFormula....", selectedFormula);
        console.log("results..", results);
        setHasEmptyParentheses(results.hasEmptyParentheses);
        setmultiple_Consecutive_Operators(results.multipleConsecutiveOperators);
        setformula_Starts_With_Operator(results.formulaStartsWithOperator);
        setformula_End_With_Operator(results.formulaEndsWithOperator);
        setinValidCharactersRegex(results.invalidCharactersRegex);
      }

      // if (selectedFormula) {
      //   console.log("selectedFormula....", selectedFormula);
      //   const hasEmptyParentheses = /\(\s*\)/.test(selectedFormula);
      //   sethasemptyParentheses(hasEmptyParentheses);
      //   console.log("hasEmptyParentheses", hasEmptyParentheses);
      //   const multiple_consecutive_operators = /[-+*/]{2,}/.test(
      //     selectedFormula
      //   );
      //   setmultiple_Consecutive_Operators(multiple_consecutive_operators);
      //   console.log(
      //     "multiple_consecutive_operators...",
      //     multiple_consecutive_operators
      //   );
      //   //  props.button_disable_reverse(hasEmptyParentheses)
      //   const formula_starts_with_operator = /^[+*/-]/.test(selectedFormula);
      //   setformula_Starts_With_Operator(formula_starts_with_operator);
      //   const formula_end_with_operator = /[+*/-]$/.test(selectedFormula);
      //   setformula_end_with_operator(formula_end_with_operator);
      //   console.log(
      //     "formula_starts_with_operator",
      //     formula_starts_with_operator
      //   );
      //   console.log("formula_end_with_operator", formula_end_with_operator);
      //   const invalidCharactersRegex = /[.,?'";]/.test(selectedFormula);
      //   setinValidCharactersRegex(invalidCharactersRegex);
      //   console.log("invalidCharactersRegex......", invalidCharactersRegex);
      // }

      if (selectedFormula == "") {
        console.log("................................nulll", selectedFormula);
        props.button_disable_reverse(true);
      }
    } catch (e) {
      console.log("error from hasEmptyParentheses check", e);
    }
  }, [selectedFormula, Button_disable]);

  useEffect(() => {
    console.log("selectedTag.....", selectedTag);
  }, [selectedTag]);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleRemoveTag = (removetag) => {
    // Find the position of the last occurrence of the tag
    const tagIndex = selectedFormula.lastIndexOf(removetag);

    if (tagIndex !== -1) {
      // Find the position of the operator preceding this tag
      let operatorIndex = -1;
      for (let i = tagIndex - 1; i >= 0; i--) {
        const char = selectedFormula.charAt(i);
        if (char === "+" || char === "-" || char === "*" || char === "/") {
          operatorIndex = i;
          break;
        }
      }

      if (operatorIndex !== -1) {
        // Remove the substring from operatorIndex to tagIndex (inclusive)
        const updatedFormula =
          selectedFormula.substring(0, operatorIndex) +
          selectedFormula.substring(tagIndex + removetag.length);

        // Set the updated formula
        setselectedFormula(updatedFormula);
      } else {
        // If no operator found, simply remove the tag
        const updatedFormula =
          selectedFormula.substring(0, tagIndex) +
          selectedFormula.substring(tagIndex + removetag.length);

        // Set the updated formula
        setselectedFormula(updatedFormula);
      }
    }
  };

  const inputRef = useRef(null);

  async function CreateSoftTagFormula() {
    try {
      const params = {
        device_id: DeviceID,
        tag_name: TagName,
        tag_description: Description,
        formula: apiFormula,
        softtag_list: tagIds,
        device_tag_list: selectedTag,
      };
      const CreateSoftTagApi = await createSoftTag(params);
      if (CreateSoftTagApi.data) {
        notifySuccess(CreateSoftTagApi.data.message);
        props.onClosePopup(false);
      }
      // console.log("CreateSoftTagApi", CreateSoftTagApi.data);
    } catch (e) {
      console.log("error in Create Soft Tag Formula:", e);
      if (e.response.data) {
        console.log(
          "error in Create Soft Tag Formula:",
          e.response.data.message
        );
        notifyError(e.response.data.message);
        // props.onClosePopup(true);
        props.handle_reverse_softagModal(true);
      } else {
        notifyError("Error in Create Soft Tag");
      }
    }
  }

  const handleinput = (e) => {
    try {
      const inputValue = e.target.value.toLowerCase();
      setselectedFormula(e.target.value);
      const filteredNames = names.filter((name) =>
        name.toLowerCase().includes(inputValue)
      );
      setMatchList(filteredNames);
    } catch (e) {
      console.log("Error from handle input time", e);
    }
  };

  const handleselectlist = (e) => {
    try {
      setselectedFormula(e.target.getAttribute("value"));

      setMatchList("");
    } catch (e) {
      console.log("Error from handleselectlist", e);
    }
  };
  const listRender = () => {
    return (
      <ul className="dd-menu">
        {matchList.map((name, index) => (
          <li key={name} value={name} onClick={(e) => handleselectlist(e)}>
            {name}
          </li>
        ))}
      </ul>
    );
  };

  // function evaluateExpression(expression) {
  //   try {
  //     const result = evaluate(expression);
  //     return result;
  //   } catch (error) {
  //     console.error("Error evaluating expression:", error);
  //     return "Error";
  //   }
  // }

  const validation_Check = () => {
    let errors = [];

    // Add your existing checks here

    if (hasemptyParentheses) {
      errors.push("Please avoid using empty parentheses.");
    }
    if (multiple_Consecutive_Operators) {
      errors.push("Please remove consecutive operators like '++, --, **, //'.");
    }
    if (formula_Starts_With_Operator) {
      errors.push(
        "Formulas should not start with an operator like '+, -, *, /'."
      );
    }
    if (formula_End_With_Operator) {
      errors.push(
        "Formulas should not end with an operator like '+, -, *, /'."
      );
    }
    if (inValidCharactersRegex) {
      errors.push(
        "Invalid characters such as ', ;' are not allowed in the formula"
      );
    }

    const tags = selectedTags;
    const operators = selectedFormula.match(/[+\-*/]+/g);

    // Check if no operators found
    if (!operators) {
      errors.push("No operators found in the formula.");
    } else if (tags.length > 1) {
      for (let i = 0; i < tags.length - 1; i++) {
        if (!operators[i]) {
          errors.push("An operator is missing between tags.");
          break; // Exit the loop once an error is found
        }
      }
    }

    // Check if any additional tags are added and if there is an operator after adding a tag

    if (errors.length > 0) {
      const errorMessage = "Error: " + errors.join(", ");
      console.log(errorMessage);
      notifyError(errorMessage);
    } else {
      CreateSoftTagFormula();
    }
  };

  function handlesubmit() {
    try {
      if (selectedFormula && DeviceID) {
        // const result = evaluateExpression(selectedFormula);
        // setResult(result);
        validation_Check();
      } else if (!selectedFormula && DeviceID) {
        notifyError("Please Formula Field is Required");
      } else if (selectedFormula && !DeviceID) {
        notifyError("DeviceID is Not Found");
      } else if (!selectedFormula && !DeviceID) {
        notifyError("Formula and DeviceID is Required");
      } else {
        console.log("Error from handlesubmit");
      }
    } catch (Error) {
      console.log("Error From handle submit Formula ..:", Error);
    }
  }
  // Example usage
  // const expression = "cos(0) + cos(1) + cos(2) + cos(3) + cos(4)";
  // const result = evaluateExpression(expression);

  // console.log("result", result);

  console.log("apiFormula", apiFormula);
  async function handleButtonClick(tag) {
    try {
      const tagToInsertAPI = props.selectSoftTag ? props.selectTagId : tag; // Get tag ID for API
      const tagToInsertInput = tag; // Use the tag name for input display

      console.log("tagToInsertAPI (For API)", tagToInsertAPI);

      if (inputRef.current.selectionStart > 0) {
        const currentCursorPosition = inputRef.current.selectionStart;

        // Build the updated formula for display
        const updatedFormula =
          selectedFormula.slice(0, currentCursorPosition) +
          tagToInsertInput + // Always insert tag name in the input field
          selectedFormula.slice(currentCursorPosition);

        setselectedFormula(updatedFormula);

        // Build the API formula with IDs
        setApiFormula((prev) => {
          // Get the existing operators and split the selectedFormula
          const operators = selectedFormula.match(/[+\-*/]+/g) || []; // Default to empty array if no matches
          const lastOperator = operators[operators.length - 1]; // Get the last operator

          // Create a new API formula
          let newApiFormula = prev;

          // Append operator and tag ID based on previous formula state
          if (prev) {
            newApiFormula += lastOperator + tagToInsertAPI; // Append the last operator before the new tag ID
          } else {
            newApiFormula += tagToInsertAPI; // No previous content, just add the tag ID
          }

          // Update tagIds based on newApiFormula
          const newTagIds = newApiFormula.split(/[+\-*/]+/).filter((id) => id); // Split by operators and filter empty strings

          // Include only numeric IDs
          const filteredTagIds = newTagIds.filter((id) => !isNaN(id));

          setTagIds(filteredTagIds);
          return newApiFormula;
        });

        console.log("updatedFormula (For Input)", updatedFormula);
        console.log("API Formula", apiFormula); // To see the API formula being built
        console.log("tagIds", tagIds); // To see the updated array of IDs

        await inputRef.current.focus();
        const newCursorPosition =
          currentCursorPosition + tagToInsertInput.length;
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      } else {
        // If no cursor position, directly insert tag name
        setselectedFormula(tagToInsertInput);
        setApiFormula(tagToInsertAPI); // Set API formula on first insert

        // Set tagIds only if the tag is a number
        if (!isNaN(tagToInsertAPI)) {
          setTagIds([tagToInsertAPI]); // Set the tag ID in the array
        } else {
          setTagIds([]); // Otherwise, set an empty array
        }
      }
    } catch (error) {
      console.log("Error from select formula", error);
    }
  }

  return (
    <>
      <div className="App">
        <div className="calc-body">
          <div className="calc-title">Scientific Calculator</div>
          <div className="input-section">
            <input
              className="screen"
              type="text"
              onChange={(e) => handleinput(e)}
              value={selectedFormula}
              autocomplete="on"
              ref={inputRef}
              required
            />

            {matchList ? listRender() : null}
            <button
              className={`scientific-calc-btn ${
                Button_disable ? "Button_disable" : ""
              }`}
              onClick={() => {
                handlesubmit();
              }}
              disabled={Button_disable}
            >
              Submit
            </button>
            {/* <div className="output">Output: {Result ? Result : null}</div> */}
          </div>
        </div>
        <div className="variables"></div>
      </div>
    </>
  );
};

export const UpdateSoftTag = function SoftTagUpdate(props) {
  // const [Result, setResult] = useState();
  // const [selectedActualFormula, setselectedActualFormula] = useState();
  const [matchList, setMatchList] = useState();
  const [selectedFormula, setselectedFormula] = useState();

  const [hasemptyParentheses, setHasEmptyParentheses] = useState(false);
  const [multiple_Consecutive_Operators, setmultiple_Consecutive_Operators] =
    useState(false);
  const [formula_Starts_With_Operator, setformula_Starts_With_Operator] =
    useState(false);
  const [formula_End_With_Operator, setformula_End_With_Operator] =
    useState(false);
  const [inValidCharactersRegex, setinValidCharactersRegex] = useState(false);
  const [apiFormula, setApiFormula] = useState(props.formulaData); // State for API formula
  const [tagIds, setTagIds] = useState([]); // State for storing tag IDs
  const [updateFormula, setUpdateFormula] = useState("");
  

  const selectedTags = props.SelectedTags;

  console.log("tagIds", tagIds);

  const [names, setnames] = useState([
    "cos()+sin()+tan()",
    "sin()+cos()+tan()",
    "tan()+cos()+sin()",
    "sin()",
    "sin()+sin()",
    "sin()+sin()+sin()",
    "sin()+sin()+sin()+sin()",
    "tan()+tan()+tan()+tan()+tan()+tan()",
    "cos()+cos()+cos()+cos()+cos()+cos()",
  ]);

  const DeviceID = props.Device_ID ? props.Device_ID : "";
  const Tag_Id = props.Tag_ID ? props.Tag_ID : "";
  const Formula = props.SoftTagFormula ? props.SoftTagFormula : "";
  const selectedTag = props.SelectedTags ? props.SelectedTags : [];

  const Button_disable = props.button_disable;

  useEffect(() => {
    try {
      if (props.addtag) {
        console.log("props.tags", props.addtag);
        handleButtonClick(props.addtag);
        props.onaddTag(props.addtag);
      }
    } catch (e) {
      console.log("Error from AddTag in Formula....", e);
    }

    if (props.removetag) {
      try {
        handleRemoveTag(props.removetag);
        props.onRemoveTag(props.removetag);
      } catch (e) {
        console.log(" error tag removing time", e);
      }
    }
  }, [props.addtag, props.removetag]);

  useEffect(() => {
    try {
      // if (selectedFormula) {
      //   console.log("selectedFormula....", selectedFormula);
      //   const hasEmptyParentheses = /\(\s*\)/.test(selectedFormula);
      //   console.log("hasEmptyParentheses", hasEmptyParentheses);
      //   props.button_disable_reverse(hasEmptyParentheses);
      // }

      if (selectedFormula) {
        // Define regex patterns once
        const regexPatterns = {
          hasEmptyParentheses: /\(\s*\)/,
          multipleConsecutiveOperators: /[-+*/]{2,}/,
          formulaStartsWithOperator: /^[+*/-]/,
          formulaEndsWithOperator: /[+*/-]$/,
          invalidCharacters: /[,?'";]/,
        };

        // Check conditions and update state in a single operation
        const results = {
          hasEmptyParentheses:
            regexPatterns.hasEmptyParentheses.test(selectedFormula),
          multipleConsecutiveOperators:
            regexPatterns.multipleConsecutiveOperators.test(selectedFormula),
          formulaStartsWithOperator:
            regexPatterns.formulaStartsWithOperator.test(selectedFormula),
          formulaEndsWithOperator:
            regexPatterns.formulaEndsWithOperator.test(selectedFormula),
          invalidCharactersRegex:
            regexPatterns.invalidCharacters.test(selectedFormula),
        };
        console.log("selectedFormula....", selectedFormula);
        console.log("results..", results);
        console.log(
          "results..",
          results.hasEmptyParentheses &&
            results.multipleConsecutiveOperators &&
            results.formulaStartsWithOperator &&
            results.formulaEndsWithOperator &&
            results.invalidCharactersRegex
        );
        const button_disable_reverse_data =
          results.hasEmptyParentheses &&
          results.multipleConsecutiveOperators &&
          results.formulaStartsWithOperator &&
          results.formulaEndsWithOperator &&
          results.invalidCharactersRegex;
        props.button_disable_reverse(button_disable_reverse_data);
        setHasEmptyParentheses(results.hasEmptyParentheses);
        setmultiple_Consecutive_Operators(results.multipleConsecutiveOperators);
        setformula_Starts_With_Operator(results.formulaStartsWithOperator);
        setformula_End_With_Operator(results.formulaEndsWithOperator);
        setinValidCharactersRegex(results.invalidCharactersRegex);
      }

      if (selectedFormula == "") {
        console.log("................................nulll", selectedFormula);
        props.button_disable_reverse(true);
      }
    } catch (e) {
      console.log("error from hasEmptyParentheses check", e);
    }
  }, [selectedFormula, Button_disable]);

  useEffect(() => {
    setselectedFormula(Formula);
  }, [Formula]);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
      autoClose: 10000,
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleRemoveTag = (removetag) => {
    const tagIndex = selectedFormula.lastIndexOf(removetag);

    if (tagIndex !== -1) {
      let operatorIndex = -1;
      for (let i = tagIndex - 1; i >= 0; i--) {
        const char = selectedFormula.charAt(i);
        if (char === "+" || char === "-" || char === "*" || char === "/") {
          operatorIndex = i;
          break;
        }
      }

      let updatedFormula;
      if (operatorIndex !== -1) {
        updatedFormula =
          selectedFormula.substring(0, operatorIndex) +
          selectedFormula.substring(tagIndex + removetag.length);
      } else {
        updatedFormula =
          selectedFormula.substring(0, tagIndex) +
          selectedFormula.substring(tagIndex + removetag.length);
      }

      setselectedFormula(updatedFormula);
      setApiFormula((prevApiFormula) => {
        const tagRegex = new RegExp(`\\b${removetag}\\b`, "g");
        const newApiFormula = prevApiFormula.replace(tagRegex, "");

        return newApiFormula.replace(/\s+/g, " ").trim();
      });

      // Optional: Update tag IDs after removing the tag
      const newTagIds = updatedFormula
        .split(/[+\-*/]+/)
        .filter((id) => !isNaN(id) && id);
      setTagIds(newTagIds);
    }
  };

  const inputRef = useRef(null);

  async function UpdateSoftTagFormula() {
    try {
      const params = {
        soft_tag_id: Tag_Id,
        formula: updateFormula,
        softtag_list: tagIds,
        device_tag_list: selectedTag,
      };
      const UpdateSoftTagApi = await updateSoftTag(params);
      if (UpdateSoftTagApi.data) {
        notifySuccess(UpdateSoftTagApi.data.message);
        props.onClosePopup(false);
      }
      // console.log("CreateSoftTagApi", CreateSoftTagApi.data);
    } catch (e) {
      console.log("error in Create Soft Tag Formula:", e);
      notifyError("Error in Update Soft Tag");
    }
  }

  const handleinput = (e) => {
    try {
      const inputValue = e.target.value.toLowerCase();
      setselectedFormula(e.target.value);
      const filteredNames = names.filter((name) =>
        name.toLowerCase().includes(inputValue)
      );
      setMatchList(filteredNames);
    } catch (e) {
      console.log("Error from handle input time", e);
    }
  };

  const handleselectlist = (e) => {
    try {
      setselectedFormula(e.target.getAttribute("value"));

      setMatchList("");
    } catch (e) {
      console.log("Error from handleselectlist", e);
    }
  };
  const listRender = () => {
    return (
      <ul className="dd-menu">
        {matchList.map((name, index) => (
          <li key={name} value={name} onClick={(e) => handleselectlist(e)}>
            {name}
          </li>
        ))}
      </ul>
    );
  };

  const validation_Check = () => {
    let errors = [];

    if (hasemptyParentheses) {
      errors.push("Empty parentheses ()");
    }
    if (multiple_Consecutive_Operators) {
      errors.push("Multiple consecutive operators ' ++ -- ** // '");
    }
    if (formula_Starts_With_Operator) {
      errors.push("Formula starts with an operator ' + - * / '");
    }
    if (formula_End_With_Operator) {
      errors.push("Formula ends with an operator ' + - * / '");
    }
    if (inValidCharactersRegex) {
      errors.push("Invalid characters ',  ; '");
    }

    const tags = selectedTags;
    const operators = selectedFormula.match(/[+\-*/]+/g);

    // Check if no operators found
    if (!operators) {
      errors.push("No operators found in the formula.");
    } else if (tags.length > 1) {
      for (let i = 0; i < tags.length - 1; i++) {
        if (!operators[i]) {
          errors.push("An operator is missing between tags.");
          break; // Exit the loop once an error is found
        }
      }
    }
    if (errors.length > 0) {
      const errorMessage = "Error: " + errors.join(", ");
      console.log(errorMessage);
      notifyError(errorMessage);
    } else {
      UpdateSoftTagFormula();
    }
  };

  function handlesubmit() {
    try {
      if (selectedFormula && DeviceID) {
        // const result = evaluateExpression(selectedFormula);
        // setResult(result);

        validation_Check();
      } else if (!selectedFormula && DeviceID) {
        notifyError("Please Formula Field is Required");
      } else if (selectedFormula && !DeviceID) {
        notifyError("DeviceID is Not Found");
      } else if (!selectedFormula && !DeviceID) {
        notifyError("Formula and DeviceID is Required");
      } else {
        console.log("Error from handlesubmit");
      }
    } catch (Error) {
      console.log("Error From handle submit Formula ..:", Error);
    }
  }

  const data = props.tagConfData;

  useEffect(() => {
    if (typeof selectedFormula === "string") {
      const formulaTags = selectedFormula.match(/\b[A-Za-z0-9_]+\b/g) || [];

      const updatedFormula = formulaTags.reduce((formula, tag) => {
        const matchingTag = data.find(
          (item) =>
            item.device_tag_list.includes(tag) ||
            (item.is_soft_tag &&
              item.soft_tag.some((soft) => soft.tag_name === tag))
        );

        if (matchingTag && matchingTag.is_soft_tag) {
          const softTag = matchingTag.soft_tag.find(
            (soft) => soft.tag_name === tag
          );
          if (softTag) {
            const tagRegex = new RegExp(`\\b${tag}\\b`, "g");
            return formula.replace(tagRegex, softTag.tag);
          }
        }

        return formula; // If no match or not a soft tag, return the formula as is
      }, selectedFormula);

      setUpdateFormula(updatedFormula);
      console.log("Updated Formula:", updatedFormula); // Log the updated formula
    } else {
      console.log("Invalid or undefined selectedFormula");
    }
  }, [selectedFormula, data, updateFormula]); // Add selectedFormula and data as dependencies

  async function handleButtonClick(tag) {
    try {
      const matchingTag = props.tagConfData.find(
        (item) =>
          item.device_tag_list.includes(tag) ||
          (item.is_soft_tag &&
            item.soft_tag.some((soft) => soft.tag_name === tag))
      );

      let tagToInsertAPI = tag; // Default to tag name
      let tagToInsertInput = tag; // Default to tag name for input

      if (matchingTag && matchingTag.is_soft_tag) {
        const softTag = matchingTag.soft_tag.find(
          (soft) => soft.tag_name === tag
        );
        if (softTag) {
          tagToInsertAPI = String(softTag.tag); // Use tag ID for API and ensure it's a string
        }
      } else {
        tagToInsertAPI = props.selectSoftTag ? String(props.selectTagId) : tag; // Ensure it's a string
      }

      if (inputRef.current.selectionStart > 0) {
        const currentCursorPosition = inputRef.current.selectionStart;

        // Build the updated formula for display
        const updatedFormula =
          selectedFormula.slice(0, currentCursorPosition) +
          tagToInsertInput +
          selectedFormula.slice(currentCursorPosition);

        // Check for consecutive operators
        const cleanFormula = updatedFormula.replace(
          /(\+{2,}|\-{2,}|\*{2,}\/{2,})/g,
          (match) => match[0]
        ); // Replace consecutive operators with a single operator

        setselectedFormula(cleanFormula);

        // Build the API formula with IDs
        setApiFormula((prev) => {
          const operators = selectedFormula.match(/[+\-*/]+/g) || [];
          const lastOperator = operators[operators.length - 1] || "";

          let newApiFormula = updateFormula || ""; // Start with an empty string if prev is undefined

          // Check for consecutive operators before adding
          if (prev) {
            if (lastOperator && lastOperator !== lastOperator[0]) {
              // Ensure last operator is not duplicate
              newApiFormula += lastOperator + String(tagToInsertAPI); // Ensure it's a string
            } else {
              newApiFormula += String(tagToInsertAPI); // Just add the tag
            }
          } else {
            newApiFormula += String(tagToInsertAPI); // Ensure it's a string
          }

          console.log("newApiFormula", newApiFormula);
          const newTagIds = newApiFormula.split(/[+\-*/]+/).filter((id) => id);
          const filteredTagIds = newTagIds.filter((id) => !isNaN(id));

          setTagIds(filteredTagIds);
          return newApiFormula;
        });

        await inputRef.current.focus();
        const newCursorPosition =
          currentCursorPosition + tagToInsertInput.length;
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      } else {
        setselectedFormula(tagToInsertInput);
        setApiFormula(String(tagToInsertAPI)); // Ensure it's a string

        if (!isNaN(tagToInsertAPI)) {
          setTagIds([tagToInsertAPI]);
        } else {
          setTagIds([]);
        }
      }
    } catch (error) {
      console.log("Error from select formula", error);
    }
  }

  return (
    <>
      <div className="App">
        <div className="calc-body">
          <div className="calc-title">Scientific Calculator</div>
          <div className="input-section">
            <input
              className="screen"
              type="text"
              onChange={(e) => handleinput(e)}
              value={selectedFormula}
              autocomplete="on"
              ref={inputRef}
              required
            />

            {matchList ? listRender() : null}
            <button
              className={`scientific-calc-btn ${
                Button_disable ? "Button_disable" : ""
              }`}
              onClick={() => {
                handlesubmit();
              }}
              disabled={Button_disable}
            >
              Submit
            </button>
            {/* <div className="output">Output: {Result ? Result : null}</div> */}
          </div>
        </div>
        <div className="variables"></div>
      </div>
    </>
  );
};
